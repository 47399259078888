<template>
  <div id="sidebar" v-if="$route.path !== '/login' && $route.path !== '/detalle'">
    <sidenav
      :orientation="orientation"
      :class="curClasses"
    >
    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo mt-2" v-if="orientation !== 'horizontal'">
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
      <span v-if="!collapsed" class="app-brand-logo demo ml-3">
        <img
          src="/images/sofyweb_logos/logoSofyweb.png"
          height="50"
      />
    </span>
      <img
        v-if="collapsed"
        src="/images/sofyweb_logos/logoMini.svg"
        style="margin-left: 0.3rem"
        weight="40"
        height="40"
      /><br />

    </div>
    <div class="sidenav-divider mt-2" v-if="orientation !== 'horizontal'"></div>
      <!-- Inner -->
      <div
        class="sidenav-inner w-100"
        :class="{ 'py-1': orientation !== 'horizontal' }"
        :active="isMenuActive('/')"
        :open="isMenuOpen('/')"
      >
        <div
          class="w-100"
        >
          <sidenav-router-link
            icon="fa-solid fa-list-radio"
            :to=menu.presupuestosHref
            :exact="true"
            @click.native="nombreVista(menu.presupuestos)"
            >{{ menu.presupuestos }}</sidenav-router-link
          >
          <sidenav-router-link
            icon="fa-solid fa-users"
            :to=menu.clientesHref
            :exact="true"
            @click.native="nombreVista(menu.clientes)"
            >{{ menu.clientes }}</sidenav-router-link
          >
        </div>
      </div>
    </sidenav>
  </div>
</template>
<style>
#sidebar {
  margin-top: -1.15rem;
}
.sidenav-link {
  color: white !important;
}
.sidenav-item:hover {
  background-color:  rgba(127, 133, 219, 0.5) !important;
}
.bg-sidenav-theme {
  background-color: #1b3063 !important;
}
.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #3e97ff;
}
</style>

<script>

import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    orientation: {
      type: String,
      default: "vertical"
    },
    idioma: {
      type: String
    }
  },
  data() {
    return {
      collapsed: false,
      menu: {
        presupuestos: "Presupuestos",
        presupuestosHref: "/presupuestos",
        clientes: "Clientes",
        clientesHref: "/clientes"
      }
    };
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },

  methods: {
    // captura del nombre de la vista a la que se navega y envío
    nombreVista(datos) {
      this.$emit("nombreVista", datos);
    },
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
      this.collapsed = !this.collapsed;
    }
  },
};
</script>
