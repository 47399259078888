<template>
  <div v-if="$route.path !== '/login'">
    <b-navbar
      id="navBar"
      toggleable="lg"
      class="align-items-lg-center container-p-x"
      style="background:transparent; height:0.5rem;  margin-top:0.2%; float: right;"
    >
      <b-collapse is-nav id="app-layout-navbar">
        <b-navbar-nav class="sidenav-link align-items-lg-center">
          <!-- Divider -->
          <div
            class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"
          >
            |
          </div>

          <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
            <template slot="button-content">
              <span
                class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
              >
                <img
                  :src="`${publicUrl}img/avatars/1.png`"
                  alt
                  class="d-block ui-w-30 rounded-circle"
                />
                <span class="px-1 mr-lg-2 ml-2 ml-lg-0" style="color:black;">{{
                  nombre
                }}</span>
              </span>
            </template>

            <b-dd-item @click="cerrarSesion"
              ><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Cerrar
              sesión</b-dd-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
// borrado del localStorage cuando el usuario cierra la ventana, incluso sin cerrar sesión
window.addEventListener("unload", function() {
  localStorage.clear();
});
// Importación de función que recoge la info o bien de sessionStorage o de localStorage
import { getFromStorage } from "../utilidades/storage.js";
export default {
  name: "app-layout-navbar",
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    },
    date: {
      type: String
    },
    dateEng: {
      type: String
    }
  },
  data() {
    return {
      isLoading: true,
      banderas: [],
      language: null,
      afterLoginMenu: {},
      nombre: ""
    };
  },
  methods: {
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    cerrarSesion() {
      this.$router.push(
        {
          path: "/login"
        },
      ).then(() => {
        localStorage.clear();
        sessionStorage.clear();
      })
    }
  },
  created() {
    // se trae el idioma, el nombre del usuario y los textos de la navbar desde localStorage
    this.nombre = getFromStorage("nombre");
    this.isLoading = false;
  }
};
</script>
