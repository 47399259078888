import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

// Layouts
import Layout1 from "@/layout/Layout1";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: "",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: Layout1,
      children: [
        {
          path: "login",
          component: () => import("@/components/Login")
        },
        {
          path: "landing",
          component: () => import("@/components/Landing")
        },
        {
          path: "presupuestos",
          component: () => import("@/components/vistas/VistaConsultaTablas")
        },
        {
          path: "detalle",
          name: "detalle",
          component: () => import("@/components/vistas/VistaDetalle")
        },
        {
          path: "nuevo-documento",
          component: () => import("@/components/vistas/VistaNuevoDocumento")
        },
        {
          path: "clientes",
          component: () => import("@/components/vistas/VistaConsultaTablas")
        },
        {
          path: "pruebaGrid",
          component: () => import("@/components/pruebasAddEditDelete/PruebaGrid")
        },
        {
          path: "pruebaTreeGrid",
          component: () => import("@/components/pruebasAddEditDelete/PruebaTreeGrid")
        },
      ]
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
